import { Content } from "../Content/ContentStore";
import { ElementDataWatcher } from "../../Library/ElementDataWatcher";
import "./ImagesViewerElement.less";
import * as contentStyle from "./ContentViewers.less";
import { Context } from "vm";
import * as viewerStyle from "./ContentViewer.less";

class ContentViewerElement extends HTMLElement {
    private dataWatcher: ElementDataWatcher<Content>;

    connectedCallback() {
        this.dataWatcher = new ElementDataWatcher(this, "content");
        this.className += ` ${contentStyle.contentViewer} ${viewerStyle.contentViewer}`;
        this.innerHTML = this.view(this.dataWatcher.data);
    }
    view = (content: Context) => `
        <div>
            <h2>Content</h2>
            <h3>text/plain</h3>
            <div class="${viewerStyle.viewer}">
                ${content["text/plain"]}
            </div>
            <h3>text/html+formatted</h3>
            <div class="${viewerStyle.viewer}">
                ${content["text/html+formatted"]}
            </div>
            <h3>text/plain+raw</h3>
            <div class="${viewerStyle.viewer}">
                ${content["text/plain+raw"]}
            </div>
        </div>
       
    `;
}

customElements.define("content-viewer", ContentViewerElement);