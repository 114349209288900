import { ElementDataWatcher } from "../../Library/ElementDataWatcher";
import * as contentStyle from "./ContentViewers.less";

abstract class StringViewerElement extends HTMLElement {
    private dataWatcher: ElementDataWatcher<string>;
    

    connectedCallback() {
        const dataName = this.tagName.split("-")[0];
        this.dataWatcher = new ElementDataWatcher(this, dataName);
        this.className += ` ${contentStyle.contentViewer}`;
        this.insertAdjacentHTML("beforeend", this.view(this.dataWatcher.data));
    }
    
    private view = (data: string) => `
        <h2>${this.title}</h2>
        <div>${data}</div>
    `;
}

class DescriptionViewerElement extends StringViewerElement {}
class TitleViewerElement extends StringViewerElement {}
class LanguageViewerElement extends StringViewerElement {}
class ReadableViewerElement extends StringViewerElement {}
class CanonicalurlViewerElement extends StringViewerElement {}
class ReadingtimeViewerElement extends StringViewerElement {}

customElements.define("description-viewer", DescriptionViewerElement);
customElements.define("title-viewer", TitleViewerElement);
customElements.define("language-viewer", LanguageViewerElement);
customElements.define("readable-viewer", ReadableViewerElement);
customElements.define("canonicalurl-viewer", CanonicalurlViewerElement);
customElements.define("readingtime-viewer", ReadingtimeViewerElement);