import { ElementDataWatcher } from "../../Library/ElementDataWatcher";
import * as contentStyle from "./ContentViewers.less";

class FeedsViewerElement extends HTMLElement {
    private dataWatcher: ElementDataWatcher<Feed[]>;

    connectedCallback() {
        this.dataWatcher = new ElementDataWatcher(this, "feeds");
        this.className += ` ${contentStyle.contentViewer}`;
        this.innerHTML = this.view(this.dataWatcher.data);
    }
    
    private view = (feeds: Feed[]) => `
        <h2>Feeds</h2>
        ${feeds.map(feed => `<div><a href="${feed.url}">${feed.title}</a> (${feed.type})</div>`).join("")}
    `;
}

interface Feed {
    url: string;
    title: string;
    type: string;
}

customElements.define("feeds-viewer", FeedsViewerElement);