

export class ElementDataWatcher<TEntity> {
    private changeObserver: MutationObserver;
    private type: string = "application/json+";
    private cachedEntity: TEntity;
    private cachedChecksum: string;
    
    // private emitChanged = (entity: TEntity) => this.emit("changed", entity);
    // public onChanged = (callback: (entity: TEntity) => void)  => this.on("changed", callback);
    // public offChanged = (callback: (entity: TEntity) => void) => this.removeListener("changed", callback);

    constructor(private rootElement: HTMLElement, entityContentType: string) {
        this.type += entityContentType;

        this.cachedChecksum = this.checksum(this.getJSON());

        this.changeObserver = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                const addedNodes = [].slice.call(mutation.addedNodes) as HTMLElement[];
                const script = addedNodes.filter(a => a.localName === "script" && a.getAttribute("type") === this.type)[0] as HTMLElement;
                if (script && script.innerText) {
                    if (this.checksum(script.innerText) !== this.cachedChecksum) {
                        this.flushCache();
                        //this.emitChanged(this.data);
                    }
                        
                }
                    
            });
        });

        this.changeObserver.observe(this.rootElement, { childList: true })
    }

    private flushCache() {
        this.cachedEntity = undefined;
    }

    private getJSON() {
        const jsonElement = this.rootElement.querySelector(`script[type='${this.type}']`) as HTMLElement;
        if (!jsonElement) {
            //console.log(`jsonElement not found for element ${this.id} (${this.innerHTML.length})`, this.innerHTML);
            return undefined;
        }

        return jsonElement.innerText;        
    }


    private parseEntity() : TEntity {
        const json = this.getJSON();
        if (json)
            return JSON.parse(json) as TEntity;
        
        return undefined;  
    }

    get data(): TEntity {
        if (this.cachedEntity)
            return this.cachedEntity;

        return this.cachedEntity = this.parseEntity();
    }

    set data(entity: TEntity) {
        const jsonElement = this.rootElement.querySelector(`script[type='${this.type}']`) as HTMLElement;
        jsonElement.innerHTML = JSON.stringify(entity);
        this.flushCache();
        //this.emitChanged(this.data);
    }

    destroy() {
        this.changeObserver.disconnect();
    }

    private checksum(source = "") : string
    {
        let chk = 0x12345678;
        const len = source.length;
        for (let i = 0; i < len; i++) 
            chk += (source.charCodeAt(i) * (i + 1));
        
        return (chk & 0xffffffff).toString(16);
    }
}