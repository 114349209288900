import { Image } from "../Content/ContentStore";
import { ElementDataWatcher } from "../../Library/ElementDataWatcher";
import * as contentStyle from "./ContentViewers.less";

class ContentTypeViewerElement extends HTMLElement {
    private dataWatcher: ElementDataWatcher<string>;

    connectedCallback() {
        this.dataWatcher = new ElementDataWatcher(this, "contentType");
        this.className += ` ${contentStyle.contentViewer}`;
        this.innerHTML = this.view(this.dataWatcher.data);
    }
    
    private view = (contentType: string) => `
        <h2>ContentType</h2>
        ${contentType}
    `;
}

customElements.define("contenttype-viewer", ContentTypeViewerElement);