import { ContentStore, ContentDocument } from "../Content/ContentStore";

document.addEventListener("DOMContentLoaded", function() {
    const urlBox = document.querySelector("input");
    urlBox.addEventListener("keypress", keypressHandler);
});



const keypressHandler = (event: KeyboardEvent) => {
    const urlBox = document.querySelector("input");
    if (event.key === "Enter") 
        showUrl(urlBox.value);
};

const shortDate = (source: string) : string => {
    const date = new Date(source);
    
    return `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;
}

const showUrl = async (checkUrl: string) => {
    const contentDocument = await ContentStore.instance.getDocument(checkUrl);
    const result = document.querySelector("[name=result]");
    result.innerHTML = contentView(contentDocument);
}

interface MalwareResponse {
    url: string;
    submitted: string;
    source: string;
    moreDetails: string;
    clean: boolean;
}

const titleize = (sentence: string) => {
    if(!sentence.split) return sentence;
    const result: string[] = [];

    var _titleizeWord = (s: string) => {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    }

    sentence.split(" ").forEach(function(w) {
        result.push(_titleizeWord(w));
    });

    return result.join(" ");
}

const contentView = (contentDocument: ContentDocument) => `
    ${Object.keys(contentDocument).map(key => `
        <${key}-viewer title="${titleize(key)}">
            <script type="application/json+${key}">
                ${JSON.stringify((<any>contentDocument)[key])}
            </script>
        </${key}-viewer>`)
    .join("")}
`;

const malwareView = (malwareResponse: MalwareResponse) => `
    <h2>
        ${malwareResponse.clean ? "Url is clean" : "Url is infected"}
    </h2>
    <div class=field>
        ${malwareResponse.source}, ${malwareResponse.submitted ? shortDate(malwareResponse.submitted) : ""}  <a target="_blank" href="${malwareResponse.moreDetails}">More info</a>
    </div>
`;
