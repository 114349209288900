import { Image } from "../Content/ContentStore";
import { ElementDataWatcher } from "../../Library/ElementDataWatcher";
import * as contentStyle from "./ContentViewers.less";

class TookViewerElement extends HTMLElement {
    private dataWatcher: ElementDataWatcher<string>;

    connectedCallback() {
        this.dataWatcher = new ElementDataWatcher(this, "took");
        this.className += ` ${contentStyle.contentViewer}`;
        this.innerHTML = this.view(this.dataWatcher.data);
    }
    
    private view = (took: string) => `
        <h2>Took</h2>
        <div>${took} milliseconds</div>
    `;
}

customElements.define("took-viewer", TookViewerElement);