export class ContentStore {
    private static _instance = new ContentStore();
    private constructor() { }
    public static get instance() {
        return this._instance;
    }

    public async getDocument(url: string): Promise<ContentDocument> {
        var serverUrl = new URL(`${window.location.protocol}//${window.location.host}/content`);
        serverUrl.searchParams.append("url", url);
        const response = await fetch(serverUrl.toString());
        const contentDocument = await response.json() as ContentDocument;

        if (contentDocument.content) {
            contentDocument.content["text/plain+formatted"] = undefined;
            contentDocument.content["text/html"] = undefined;
        }

        return contentDocument;
    }
}


export interface ThreatLevel {
    kind: string;
    url: string;
    submitted: Date;
    clean: boolean;
    badUrl: boolean;
    types: any[];
}

export interface Dimensions {
    width: number;
    height: number;
}

export interface Image {
    url: string;
    relation: string;
    score: number;
    dimensions: Dimensions;
    urlTemplate: string;
}

export interface Content {
    [Key: string]: string;
}

export interface ContentDocument {
    took: number;
    contentType: string;
    description: string;
    title: string;
    language: string;
    readable: boolean;
    threatLevel: ThreatLevel;
    canonicalUrl: string;
    readingTime: string;
    feeds: any[];
    images: Image[];
    content: Content;
}

